const listHelper = {
  Countries: [
    { view: "Afghanistan", value: "AF" },
    { view: "Åland Islands", value: "AX" },
    { view: "Albania", value: "AL" },
    { view: "Algeria", value: "DZ" },
    { view: "American Samoa", value: "AS" },
    { view: "Andorra", value: "AD" },
    { view: "Angola", value: "AO" },
    { view: "Anguilla", value: "AI" },
    { view: "Antarctica", value: "AQ" },
    { view: "Antigua and Barbuda", value: "AG" },
    { view: "Argentina", value: "AR" },
    { view: "Armenia", value: "AM" },
    { view: "Aruba", value: "AW" },
    { view: "Australia", value: "AU" },
    { view: "Austria", value: "AT" },
    { view: "Azerbaijan", value: "AZ" },
    { view: "Bahamas", value: "BS" },
    { view: "Bahrain", value: "BH" },
    { view: "Bangladesh", value: "BD" },
    { view: "Barbados", value: "BB" },
    { view: "Belarus", value: "BY" },
    { view: "Belgium", value: "BE" },
    { view: "Belize", value: "BZ" },
    { view: "Benin", value: "BJ" },
    { view: "Bermuda", value: "BM" },
    { view: "Bhutan", value: "BT" },
    { view: "Bolivia (Plurinational State of)", value: "BO" },
    { view: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
    { view: "Bosnia and Herzegovina", value: "BA" },
    { view: "Botswana", value: "BW" },
    { view: "Bouvet Island", value: "BV" },
    { view: "Brazil", value: "BR" },
    { view: "British Indian Ocean Territory", value: "IO" },
    { view: "Brunei Darussalam", value: "BN" },
    { view: "Bulgaria", value: "BG" },
    { view: "Burkina Faso", value: "BF" },
    { view: "Burundi", value: "BI" },
    { view: "Cabo Verde", value: "CV" },
    { view: "Cambodia", value: "KH" },
    { view: "Cameroon", value: "CM" },
    { view: "Canada", value: "CA" },
    { view: "Cayman Islands", value: "KY" },
    { view: "Central African Republic", value: "CF" },
    { view: "Chad", value: "TD" },
    { view: "Chile", value: "CL" },
    { view: "China", value: "CN" },
    { view: "Christmas Island", value: "CX" },
    { view: "Cocos (Keeling) Islands", value: "CC" },
    { view: "Colombia", value: "CO" },
    { view: "Comoros", value: "KM" },
    { view: "Congo", value: "CG" },
    { view: "Congo (Democratic Republic of the)", value: "CD" },
    { view: "Cook Islands", value: "CK" },
    { view: "Costa Rica", value: "CR" },
    { view: "Côte d'Ivoire", value: "CI" },
    { view: "Croatia", value: "HR" },
    { view: "Cuba", value: "CU" },
    { view: "Curaçao", value: "CW" },
    { view: "Cyprus", value: "CY" },
    { view: "Czechia", value: "CZ" },
    { view: "Denmark", value: "DK" },
    { view: "Djibouti", value: "DJ" },
    { view: "Dominica", value: "DM" },
    { view: "Dominican Republic", value: "DO" },
    { view: "Ecuador", value: "EC" },
    { view: "Egypt", value: "EG" },
    { view: "El Salvador", value: "SV" },
    { view: "Equatorial Guinea", value: "GQ" },
    { view: "Eritrea", value: "ER" },
    { view: "Estonia", value: "EE" },
    { view: "Ethiopia", value: "ET" },
    { view: "Falkland Islands (Malvinas)", value: "FK" },
    { view: "Faroe Islands", value: "FO" },
    { view: "Fiji", value: "FJ" },
    { view: "Finland", value: "FI" },
    { view: "France", value: "FR" },
    { view: "French Guiana", value: "GF" },
    { view: "French Polynesia", value: "PF" },
    { view: "French Southern Territories", value: "TF" },
    { view: "Gabon", value: "GA" },
    { view: "Gambia", value: "GM" },
    { view: "Georgia", value: "GE" },
    { view: "Germany", value: "DE" },
    { view: "Ghana", value: "GH" },
    { view: "Gibraltar", value: "GI" },
    { view: "Greece", value: "GR" },
    { view: "Greenland", value: "GL" },
    { view: "Grenada", value: "GD" },
    { view: "Guadeloupe", value: "GP" },
    { view: "Guam", value: "GU" },
    { view: "Guatemala", value: "GT" },
    { view: "Guernsey", value: "GG" },
    { view: "Guinea", value: "GN" },
    { view: "Guinea-Bissau", value: "GW" },
    { view: "Guyana", value: "95" },
    { view: "Haiti", value: "HT" },
    { view: "Heard Island and McDonald Islands", value: "HM" },
    { view: "Holy See", value: "VA" },
    { view: "Honduras", value: "HN" },
    { view: "Hong Kong", value: "HK" },
    { view: "Hungary", value: "HU" },
    { view: "Iceland", value: "IS" },
    { view: "India", value: "IN" },
    { view: "Indonesia", value: "ID" },
    { view: "Iran (Islamic Republic of)", value: "IR" },
    { view: "Iraq", value: "IQ" },
    { view: "Ireland", value: "IE" },
    { view: "Isle of Man", value: "IM" },
    { view: "Israel", value: "IL" },
    { view: "Italy", value: "IT" },
    { view: "Jamaica", value: "JM" },
    { view: "Japan", value: "JP" },
    { view: "Jersey", value: "JE" },
    { view: "Jordan", value: "JO" },
    { view: "Kazakhstan", value: "KZ" },
    { view: "Kenya", value: "KE" },
    { view: "Kiribati", value: "KI" },
    { view: "Korea (Democratic People's Republic of)", value: "KP" },
    { view: "Korea (Republic of)", value: "KR" },
    { view: "Kuwait", value: "KW" },
    { view: "Kyrgyzstan", value: "KG" },
    { view: "Lao People's Democratic Republic", value: "LA" },
    { view: "Latvia", value: "LV" },
    { view: "Lebanon", value: "LB" },
    { view: "Lesotho", value: "LS" },
    { view: "Liberia", value: "LR" },
    { view: "Libya", value: "LY" },
    { view: "Liechtenstein", value: "LI" },
    { view: "Lithuania", value: "LT" },
    { view: "Luxembourg", value: "LU" },
    { view: "Macao", value: "MO" },
    { view: "Macedonia (the former Yugoslav Republic of)", value: "MK" },
    { view: "Madagascar", value: "MG" },
    { view: "Malawi", value: "MW" },
    { view: "Malaysia", value: "MY" },
    { view: "Maldives", value: "MV" },
    { view: "Mali", value: "ML" },
    { view: "Malta", value: "MT" },
    { view: "Marshall Islands", value: "MH" },
    { view: "Martinique", value: "MQ" },
    { view: "Mauritania", value: "MR" },
    { view: "Mauritius", value: "MU" },
    { view: "Mayotte", value: "YT" },
    { view: "Mexico", value: "MX" },
    { view: "Micronesia (Federated States of)", value: "FM" },
    { view: "Moldova (Republic of)", value: "MD" },
    { view: "Monaco", value: "MC" },
    { view: "Mongolia", value: "MN" },
    { view: "Montenegro", value: "ME" },
    { view: "Montserrat", value: "MS" },
    { view: "Morocco", value: "MA" },
    { view: "Mozambique", value: "MZ" },
    { view: "Myanmar", value: "MM" },
    { view: "Namibia", value: "NA" },
    { view: "Nauru", value: "NR" },
    { view: "Nepal", value: "NP" },
    { view: "Netherlands", value: "NL" },
    { view: "New Caledonia", value: "NC" },
    { view: "New Zealand", value: "NZ" },
    { view: "Nicaragua", value: "NI" },
    { view: "Niger", value: "NE" },
    { view: "Nigeria", value: "NG" },
    { view: "Niue", value: "NU" },
    { view: "Norfolk Island", value: "NF" },
    { view: "Northern Mariana Islands", value: "MP" },
    { view: "Norway", value: "NO" },
    { view: "Oman", value: "OM" },
    { view: "Pakistan", value: "PK" },
    { view: "Palau", value: "PW" },
    { view: "Palestine, State of", value: "PS" },
    { view: "Panama", value: "PA" },
    { view: "Papua New Guinea", value: "PG" },
    { view: "Paraguay", value: "PY" },
    { view: "Peru", value: "PE" },
    { view: "Philippines", value: "PH" },
    { view: "Pitcairn", value: "PN" },
    { view: "Poland", value: "PL" },
    { view: "Portugal", value: "PT" },
    { view: "Puerto Rico", value: "PR" },
    { view: "Qatar", value: "QA" },
    { view: "Réunion", value: "RE" },
    { view: "Romania", value: "RO" },
    { view: "Russian Federation", value: "RU" },
    { view: "Rwanda", value: "RW" },
    { view: "Saint Barthélemy", value: "BL" },
    { view: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
    { view: "Saint Kitts and Nevis", value: "KN" },
    { view: "Saint Lucia", value: "LC" },
    { view: "Saint Martin (French part)", value: "MF" },
    { view: "Saint Pierre and Miquelon", value: "PM" },
    { view: "Saint Vincent and the Grenadines", value: "VC" },
    { view: "Samoa", value: "WS" },
    { view: "San Marino", value: "SM" },
    { view: "Sao Tome and Principe", value: "ST" },
    { view: "Saudi Arabia", value: "SA" },
    { view: "Senegal", value: "SN" },
    { view: "Serbia", value: "RS" },
    { view: "Seychelles", value: "SC" },
    { view: "Sierra Leone", value: "SL" },
    { view: "Singapore", value: "SG" },
    { view: "Sint Maarten (Dutch part)", value: "SX" },
    { view: "Slovakia", value: "SK" },
    { view: "Slovenia", value: "SI" },
    { view: "Solomon Islands", value: "SB" },
    { view: "Somalia", value: "SO" },
    { view: "South Africa", value: "ZA" },
    { view: "South Georgia and the South Sandwich Islands", value: "GS" },
    { view: "South Sudan", value: "SS" },
    { view: "Spain", value: "ES" },
    { view: "Sri Lanka", value: "LK" },
    { view: "Sudan", value: "SD" },
    { view: "Suriname", value: "SR" },
    { view: "Svalbard and Jan Mayen", value: "SJ" },
    { view: "Swaziland", value: "SZ" },
    { view: "Sweden", value: "SE" },
    { view: "Switzerland", value: "CH" },
    { view: "Syrian Arab Republic", value: "SY" },
    { view: "Taiwan, Province of China[a]", value: "TW" },
    { view: "Tajikistan", value: "TJ" },
    { view: "Tanzania, United Republic of", value: "TZ" },
    { view: "Thailand", value: "TH" },
    { view: "Timor-Leste", value: "TL" },
    { view: "Togo", value: "TG" },
    { view: "Tokelau", value: "TK" },
    { view: "Tonga", value: "TO" },
    { view: "Trinidad and Tobago", value: "TT" },
    { view: "Tunisia", value: "TN" },
    { view: "Turkey", value: "TR" },
    { view: "Turkmenistan", value: "TM" },
    { view: "Turks and Caicos Islands", value: "TC" },
    { view: "Tuvalu", value: "TV" },
    { view: "Uganda", value: "UG" },
    { view: "Ukraine", value: "UA" },
    { view: "United Arab Emirates", value: "AE" },
    {
      view: "United Kingdom of Great Britain and Northern Ireland",
      value: "GB",
    },
    { view: "United States of America", value: "US" },
    { view: "United States Minor Outlying Islands", value: "UM" },
    { view: "Uruguay", value: "UY" },
    { view: "Uzbekistan", value: "UZ" },
    { view: "Vanuatu", value: "VU" },
    { view: "Venezuela (Bolivarian Republic of)", value: "VE" },
    { view: "Viet Nam", value: "VN" },
    { view: "Virgin Islands (British)", value: "VG" },
    { view: "Virgin Islands (U.S.)", value: "VI" },
    { view: "Wallis and Futuna", value: "WF" },
    { view: "Western Sahara", value: "EH" },
    { view: "Yemen", value: "YE" },
    { view: "Zambia", value: "ZM" },
    { view: "Zimbabwe", value: "ZW" },
  ],
  States: [
    { view: "Acre", value: "AC" },
    { view: "Alagoas", value: "AL" },
    { view: "Amazonas", value: "AM" },
    { view: "Amapá", value: "AP" },
    { view: "Bahia", value: "BA" },
    { view: "Ceará", value: "CE" },
    { view: "Distrito Federal", value: "DF" },
    { view: "Espírito Santo", value: "ES" },
    { view: "Goiás", value: "GO" },
    { view: "Maranhão", value: "MA" },
    { view: "Minas Gerais", value: "MG" },
    { view: "Mato Grosso do Sul", value: "MS" },
    { view: "Mato Grosso", value: "MT" },
    { view: "Pará", value: "PA" },
    { view: "Paraíba", value: "PB" },
    { view: "Pernambuco", value: "PE" },
    { view: "Piauí", value: "PI" },
    { view: "Paraná", value: "PR" },
    { view: "Rio de Janeiro", value: "RJ" },
    { view: "Rio Grande do Norte", value: "RN" },
    { view: "Rondônia", value: "RO" },
    { view: "Roraima", value: "RR" },
    { view: "Rio Grande do Sul", value: "RS" },
    { view: "Santa Catarina", value: "SC" },
    { view: "Sergipe", value: "SE" },
    { view: "São Paulo", value: "SP" },
    { view: "Tocantins", value: "TO" },
  ],
};

export default listHelper;
